import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor() { }

  selectColor(index: number) {
    const goldenAngle = 180 * (3 - Math.sqrt(5)); // use golden angle approximation
    return [(index + 1) * goldenAngle + 60, 100, 75];
  }

  hslToHex(hsl: number[]) {
    let h = hsl[0];
    let s = hsl[1];
    let l = hsl[2];

    l /= 100;

    const a = s * Math.min(l, 1 - l) / 100;
    const f = (n: number) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };

    return `#${f(0)}${f(8)}${f(4)}`;
  }

  getPaletteOfColors(count: number) {
    return Array.from({ length: count }, (_, index) => this.hslToHex(this.selectColor(index)));
  }
}
